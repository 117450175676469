<main class="general">
  <section class="general__info">
    <p class="general__info-title">
      Transacción
      {{ states()[infoTransaction().id_pay_status] }}
    </p>
    @if (!showOutHour() && !showWeekend()) {
      <p>
        @if (infoTransaction().id_pay_status === 9) {
          <span>
            Tu solicitud no se ha generado. Intenta de nuevo
          </span>
        } @else {
          <span>
            Recibirás el certificado en el correo <span class="bold">{{ infoTransaction().email }}</span> en los próximos 30 minutos
          </span>
        }
      </p>
    }
    @if (showOutHour() && !showWeekend()) {
      <p>
        @if (infoTransaction().id_pay_status === 9) {
          <span>
            Tu solicitud no se ha generado. Intenta de nuevo
          </span>
        } @else {
          <span>
            Tu certificado(s) será generado a partir de las 6:30 am. Recibirás el certificado en el correo indicado
          </span>
        }
      </p>
    }
    @if (showWeekend()) {
      <p>
        <span>
          Tu certificado(s) será generado a partir de las 6:30 am de lunes a sabado
        </span>
        <span>
          Recibirás el certificado en el correo indicado
        </span>
      </p>
    }
    <div class="general__info-content">
      <div>
        <p>Fecha</p>
        <p>{{ infoTransaction().created_at | date:'medium' }}</p>
      </div>
      <div>
        <p>Valor</p>
        <p>{{ infoTransaction().amount | currency }}</p>
      </div>
      @if (infoTransaction().id_pay_status != 9) {
        <div>
          <p>ID de pago</p>
          <p>{{ infoTransaction().pay_id || '---' }}</p>
        </div>
      }
      <div>
        <p>Medio de pago</p>
        <p>{{ meansPay()[infoTransaction().id_payment_method] }}</p>
      </div>
      <div>
        <p>Estado del pago</p>
        <p>{{ states()[infoTransaction().id_pay_status] }}</p>
      </div>
      @if (infoTransaction().id_pay_status != 9) {
        <div>
          <p>ID de la solicitud</p>
          <p>{{ infoTransaction().id_request }}</p>
        </div>
      }
      @if (infoTransaction().id_pay_status != 9) {
        <div>
          <p>Correo</p>
          <p>{{ infoTransaction().email }}</p>
        </div>
      }
    </div>
    <div class="general__info-btns">
      <app-btn-normal
        routerLink="/"
        [contentBtn]="'Finalizar'"
      ></app-btn-normal>
    </div>
  </section>
</main>
