<app-header [showTitle]="false"></app-header>
<main>
  <div class="initial-text">
    <p class="title-blog">
      Aquí encontrarás información detallada sobre los Certificados de Tradición y Libertad, el sector inmobiliario y mucho más.
    </p>
  </div>
  <section class="filter">
    <div class="search-wrapper">
      <form>
      <input type="text" name="focus" required class="search-box" placeholder="Ingrese el termino de busqueda" />
        <button class="close-icon" type="reset"></button>
      </form>
    </div>
  </section>
  <section class="all-post">
    <article *ngFor="let post of listPost()">
      <img [src]="post[1]?.img" alt="Imagen del post">
      <a
        [href]="'/blog/post/' + post[1].titleUrl + '/' + post[0]"
        class="title-post"
      >{{ post[1].title }}</a>
      <p class="date">{{ post[1].date | date:'medium' || '---' }}</p>
      <p class="intro">{{ post[1].introduction.slice(0, 100) }}<span *ngIf="post[1].introduction.length > 100">...</span></p>
    </article>
  </section>
</main>
