import { Component, Inject, OnInit, inject, signal } from '@angular/core';
import { NgFor, DatePipe, NgIf, DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
// import { Storage, ref, listAll, getDownloadURL } from '@angular/fire/storage'

import { BlogService } from '../services/blog.service';
import { AlertService } from '../../services/alert.service';

import { HeaderComponent } from '../../shared/header/header.component';

@Component({
  selector: 'app-list',
  standalone: true,
  imports: [HeaderComponent, NgFor, NgIf, DatePipe],
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  listPost = signal<any[]>([]);

  private blogService = inject(BlogService)
  private router = inject(Router)
  private titleService = inject(Title)
  private metaService = inject(Meta)

  constructor(
    @Inject(DOCUMENT) private dom: any
  ) { }

  ngOnInit(): void {
    this.blogService.getAll()
    this.setAllPost()
    this.setSEOData('Blog', 'Resuelve aquí todas las preguntas que puedas tener sobre el Certificado de Libertad y Tradición, sus usos, su proceso de descarga y metodos de pago.')
    this.createCanonicalURL()
  }

  setSEOData(title: string, description: string) {
    this.titleService.setTitle(title);
    this.metaService.updateTag({ name: 'description', content: description });
  }

  // createCanonicalURL(url: string) {
  //   const head = this.dom.getElementsByTagName('head')[0];
  //   let element: HTMLLinkElement= this.dom.querySelector(`link[rel='canonical']`) || null
  //   if (element == null) {
  //     element= this.dom.createElement('link') as HTMLLinkElement;
  //     head.appendChild(element);
  //   }
  //   element.setAttribute('rel','canonical')
  //   element.setAttribute('href', url)
  // }

  createCanonicalURL() {
    const baseUrl = 'https://certificadostradicionylibertad.co';
    const path = this.router.url.split('?')[0];
    const canonicalUrl = baseUrl + path;

    const head = this.dom.getElementsByTagName('head')[0];
    let element: HTMLLinkElement = this.dom.querySelector(`link[rel='canonical']`) || null;
    if (element == null) {
      element = this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', canonicalUrl);
  }

  setAllPost() {
    this.blogService.listPost$.subscribe(data => {
      if (data.length) this.listPost.set(data)
    })
  }

  // onPost(titleUrl: string, id: string) {
  //   this.router.navigate([`/blog/post/${titleUrl}/`], {
  //     queryParams: { post: id },
  //     skipLocationChange: false,
  //   })
  // }

}
